<template>
  <v-app :class="siteMode === 'UH' ? 'login-app-uh-marketing' : 'login-app'">
    <v-container class="login-container">
      <v-row class="mb-8">
        <v-col cols="12" md="6" class="d-flex justify-left align-left">
          <div
            :class="
              $mq === 'mobile'
                ? 'd-flex justify-center align-end'
                : 'd-flex justify-left align-end'
            "
          >
            <v-img
              :src="siteMode === 'UH' ? logoUH : logoVP"
              class="mt-3"
              contain
              :width="siteMode === 'UH' ? 150 : 150"
            />
            <h1
              :class="
                siteMode === 'UH'
                  ? 'display-2 white--text font-weight-bold'
                  : 'display-2 white--text font-weight-bold'
              "
            >
              {{ siteMode === 'UH' ? 'VPExam' : 'VPExam' }}
            </h1>
          </div>
        </v-col>
      </v-row>
      <v-card class="login-card">
        <v-row align="center" justify="center">
          <v-col
            cols="12"
            md="6"
            :class="$mq === 'mobile' ? '' : 'fill-height'"
          >
            <v-card flat>
              <v-card-title class="text-md-h4"
                >Login To Your Account</v-card-title
              >
              <v-card-text>
                <v-form
                  id="form"
                  ref="form"
                  v-model="valid"
                  @submit.prevent="signIn"
                >
                  <v-text-field
                    v-model="form.email"
                    name="email"
                    label="Email"
                    type="text"
                    :rules="[rules.required]"
                    outlined
                    rounded
                    dense
                  />
                  <v-text-field
                    id="password"
                    v-model="form.password"
                    :type="hidePassword ? 'password' : 'text'"
                    :append-icon="
                      hidePassword ? 'visibility_off' : 'visibility'
                    "
                    name="password"
                    label="Password"
                    :rules="[rules.required]"
                    outlined
                    rounded
                    dense
                    @click:append="hidePassword = !hidePassword"
                  />
                </v-form>
                <div
                  :style="
                    $mq === 'mobile'
                      ? ''
                      : 'display: flex;justify-content: space-between;align-items: flex-end;'
                  "
                >
                  <v-btn
                    :block="$mq === 'mobile'"
                    color="primary"
                    :loading="loading"
                    :disabled="!valid"
                    @click="signIn"
                    style="float: right;"
                    >Sign In</v-btn
                  >
                  <div style="margin-top: 12px;">
                    <ForgotPasswordDialog></ForgotPasswordDialog>
                  </div>
                </div>
                <div class="mt-4">
                  <v-btn
                    to="/patientregistration"
                    color="primary"
                    style="padding: 0 5px;"
                    text
                  >
                    <span class="mr-1" style="color: #9E9E9E !important;"
                      >Don't have an account?</span
                    >
                    Register Here!
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" class="fill-height">
            <v-card
              :class="
                $mq === 'mobile'
                  ? 'd-flex flex-column justify-space-between'
                  : 'd-flex flex-column justify-space-between fill-height'
              "
              flat
            >
              <v-card-title class="text-md-h4"
                >Login as Guest User</v-card-title
              >
              <v-card-text class="black--text text-md-h6"
                >VPExam gives your doctor all the critical information needed to
                provide the highest quality of care.</v-card-text
              >
              <v-card-text>
                <v-btn color="primary" @click="guestDialog = true"
                  >Get Started</v-btn
                >
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-snackbar v-model="snackbar" absolute>
          {{ error }}
          <v-btn color="red" text @click="snackbar = false">Okay</v-btn>
        </v-snackbar>
      </v-card>
      <v-dialog max-width="600px" v-model="guestDialog">
        <v-card>
          <v-card-title>
            Guest Registration
            <v-spacer></v-spacer>
            <v-btn color="error" @click="guestDialog = false" text
              >Cancel</v-btn
            >
          </v-card-title>
          <v-card-text>
            <GuestRegister />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
import PublicLayout from '@/layouts/PublicLayout'
import { auth } from '../../firebase'
import { logLogin } from '../../Events'
import ForgotPasswordDialog from '../../components/dialogs/ForgotPasswordDialog'
import logoUH from '@/assets/logo-white.png'
import logoVP from '@/assets/logo-white.png'
import GuestRegister from '@/patientViews/components/GuestRegister'

export default {
  name: 'Login',
  components: { ForgotPasswordDialog, GuestRegister },
  data() {
    return {
      logoUH: logoUH,
      logoVP: logoVP,
      siteMode: process.env.VUE_APP_SITE,
      auth,
      guestDialog: false,
      valid: false,
      loading: false,
      error: null,
      snackbar: false,
      hidePassword: true,
      form: {
        email: '',
        password: ''
      },
      rules: {
        required: value => !!value || 'Required.'
      }
    }
  },
  created() {
    this.$emit(`update:layout`, PublicLayout)
  },
  methods: {
    updateUser() {
      auth.currentUser.updateProfile({
        displayName: 'proxy'
      })
    },
    signIn() {
      this.$refs.form.validate()
      if (!this.valid) return

      this.loading = true
      setTimeout(() => {
        this.auth
          .signInWithEmailAndPassword(this.form.email, this.form.password)
          .then(() => {
            if (auth.currentUser.emailVerified === true) {
              this.loading = false
              this.$refs.form.reset()
              if (auth.currentUser.displayName === 'proxy') {
                this.$router.push(this.$route.query.redirect || '/appointments')
                logLogin()
              } else if (auth.currentUser.displayName === 'patient') {
                this.$router.push(
                  this.$route.query.redirect || '/my-appointments'
                )
              } else if (auth.currentUser.displayName === 'partner') {
                this.$router.push(
                  this.$route.query.redirect || '/partner/appointments'
                )
              }
            } else {
              auth.signOut()
              this.$router.push(this.$route.query.redirect || '/thankyou')
            }
          })
          .catch(error => {
            this.error = error.message
            this.loading = false
            this.snackbar = true
          })
      }, 750)
    },
    validate() {
      this.$refs.form.validate()
    },
    clearForm() {
      this.$refs.form.reset()
      Object.keys(this.form).forEach(key => (this.form[key] = null))
    }
  }
}
</script>

<style scoped>
#form {
  margin-top: 0;
}
/* 
.container {
  max-width: 737px !important;
} */

.login-container {
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.login-card {
  width: 100%;
  overflow: hidden;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 100px;
}
.v-btn {
  text-transform: capitalize;
}

@media (max-width: 768px) {
}
</style>
