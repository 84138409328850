import { analytics, db } from './firebase'

export function logUpdatePractice(userId, practiceId) {
  analytics.logEvent('update_practice', {
    user_id: userId,
    practice_id: practiceId,
    time: db.app.firebase_.firestore.Timestamp.fromDate(new Date()).toDate()
  })
}

//user logged in
export function logLogin() {
  analytics.logEvent('login', {
    method: 'email',
    time: db.app.firebase_.firestore.Timestamp.fromDate(new Date()).toDate()
  })
}

//user sign up
export function logSignUp() {
  analytics.logEvent('sign_up', {
    method: 'email',
    time: db.app.firebase_.firestore.Timestamp.fromDate(new Date()).toDate()
  })
}

//database updated
//User edited patient details
export function logPatientDeleted(userId, practiceId, patientId) {
  analytics.logEvent('patient_edited', {
    user_id: userId,
    practice_id: practiceId,
    patient_id: patientId,
    time: db.app.firebase_.firestore.Timestamp.fromDate(new Date()).toDate()
  })
}

//User edited patient details
export function logPatientEdited(userId, practiceId, patientId) {
  analytics.logEvent('patient_edited', {
    user_id: userId,
    practice_id: practiceId,
    patient_id: patientId,
    time: db.app.firebase_.firestore.Timestamp.fromDate(new Date()).toDate()
  })
}

//User viewed patient details
export function logPatientViewed(userId, practiceId, patientId) {
  analytics.logEvent('patient_viewed', {
    user_id: userId,
    practice_id: practiceId,
    patient_id: patientId,
    time: db.app.firebase_.firestore.Timestamp.fromDate(new Date()).toDate()
  })
}

//files accessed by user
export function logFileViewed(userId, practiceId, file) {
  analytics.logEvent('file_viewed', {
    user_id: userId,
    practice_id: practiceId,
    file_id: file,
    time: db.app.firebase_.firestore.Timestamp.fromDate(new Date()).toDate()
  })
}

//new user and level of access
export function logNewUser(userId, practiceId, email, role) {
  analytics.logEvent('user_registered', {
    user_id: userId,
    practice_id: practiceId,
    invite_email: email,
    role: role,
    time: db.app.firebase_.firestore.Timestamp.fromDate(new Date()).toDate()
  })
}

//new user invited
export function logInviteUser(userId, practiceId, email, role) {
  analytics.logEvent('user_invited', {
    user_id: userId,
    practice_id: practiceId,
    invite_email: email,
    role: role,
    time: db.app.firebase_.firestore.Timestamp.fromDate(new Date()).toDate()
  })
}
