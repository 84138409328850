<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on }">
      <v-btn
        @click="dialog = true"
        v-on="on"
        color="grey"
        style="padding: 0 5px;"
        text
      >
        Forgot Password?
      </v-btn>
    </template>

    <v-card>
      <v-form>
        <v-container>
          <v-row>
            <v-col>
              <h3>
                Enter your email address. We will email instructions on how to
                reset your password
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                name="email"
                label="Email"
                type="email"
                v-model="email"
                outlined
                rounded
                dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                @click="forgotPassword()"
                color="primary"
                :loading="loading"
                block
                >Reset Password</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { auth } from '../../firebase'
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      email: null
    }
  },
  methods: {
    async forgotPassword() {
      this.loading = true
      try {
        await auth.sendPasswordResetEmail(this.email)
        this.loading = false
        this.dialog = false
      } catch (error) {
        this.loading = false
      }
    }
  }
}
</script>
